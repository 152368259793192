import React, { useState } from "react";
import Icon from "../custom-widgets/icon";
import ServiceStatesLinks from "../service-states/service-states-links";
import Button from "../custom-widgets/button";
import LoanOfficerSearch from "../loan-officers/loan-officer-search";

const QuickLinksFlex = ({ id, linksData = [], rowClass = "", className = "", rowId, isSpanish }) => {
  const initialImageState = new Array(linksData.length).fill(true);
  const [activeImage, setActiveImage] = useState(initialImageState);
  function updateImageState(index, value) {
    let newState = [...initialImageState];
    newState[index] = value;
    setActiveImage(newState);
  }

  const serviceStateLinksData = {
    class: "",
    hasTexasLink: true
  };

  const [showLOModalMortgage, setShowLOModalMortgage] = useState(false);
  const handleCloseLOModalMortgage = () => setShowLOModalMortgage(false);
  const handleShowLOModalMortgage = () => {
    setShowLOModalMortgage(true);
  };

  const [showLOModalHomeEquity, setShowLOModalHomeEquity] = useState(false);
  const handleCloseLOModalHomeEquity = () => setShowLOModalHomeEquity(false);
  const handleShowLOModalHomeEquity = () => {
    setShowLOModalHomeEquity(true);
  };

  return (
    <section id={id} className={`container ${className}`}>
      <div id={rowId} className={`row ${rowClass}`}>
        {linksData.map((obj, index) => (
          <div className="col-12 col-md-6">
            <div
              id={obj.id}
              key={index}
              tabIndex={0}
              onMouseEnter={() => updateImageState(index, false)}
              onMouseLeave={() => updateImageState(index, true)}
              className={`d-flex align-items-start align-items-start p-3 mb-3 ${
                activeImage[index] || !obj.url ? "bg-white" : "bg-gray-10"
              }`}
              style={{ borderRadius: "12px" }}
            >
              <a href={obj.url} className="stretched-link">
                <span className="sr-only">{obj.text}</span>
              </a>

              <img
                alt=""
                className={`mx-auto ${activeImage[index] === true ? "d-flex" : "d-none"}`}
                src={obj.icon}
                style={{ width: "80px" }}
              />
              <img
                alt=""
                className={`mx-auto ${activeImage[index] === true ? "d-none" : "d-flex"}`}
                src={obj.url ? obj.hoverIcon : obj.icon}
                style={{ width: "80px" }}
              />
              <div className="ml-4">
                {obj.url ? (
                  <a
                    href={obj.url}
                    id={obj.id}
                    className="h4-font-size font-weight-bold stretched-link text-decoration-none"
                  >
                    {obj.text}
                    <Icon name="arrow-right" lib="fas" class="ml-1" />
                  </a>
                ) : (
                  <h4 id={obj.id} className="font-weight-bold text-gray-90 mb-0">
                    {obj.text}
                  </h4>
                )}
                <p id={obj.id + "-description"} className="font-weight-semi-bold text-secondary mb-0">
                  {obj.description}
                </p>
              </div>
            </div>
          </div>
        ))}
        <div className="col-12 col-md-6">
          <div className="d-flex p-3">
            <img
              alt=""
              className={"mx-auto d-flex d-sm-none d-md-block"}
              src={"../../images/icons/first-time-buyer-icon-active.svg"}
              style={{ minWidth: "70px", opacity: 0 }}
            />
            <div className="ml-md-4">
              <h5 id={"suggestions-text"} className="font-weight-bold text-green-60 mb-0">
                {!isSpanish
                  ? "If you've done all your research but aren't sure whether a home loan makes sense, just stop by."
                  : "Si aún tienes preguntas sobre préstamos hipotecarios, te esperamos."}
              </h5>
              <p id={"we-are-nearby-description"} className="text-secondary">
                {!isSpanish
                  ? "We're nearby, and we'll sit down with you and answer any questions you may have."
                  : "Estamos cerca, y estamos felices de sentarnos contigo para responder todas tus preguntas."}{" "}
                <ServiceStatesLinks {...serviceStateLinksData} />.
              </p>
              <LoanOfficerSearch
                isModalSearch={true}
                show={showLOModalMortgage}
                handleClose={handleCloseLOModalMortgage}
                loanType="Home Loan"
              />

              <LoanOfficerSearch
                isModalSearch={true}
                show={showLOModalHomeEquity}
                handleClose={handleCloseLOModalHomeEquity}
                loanType="HELOC" // Can be HELOAN too
              />

              {isSpanish && (
                <>
                  <Button
                    id="apply-for-mortgage-cta"
                    type="button"
                    text="Solicitar un préstamo hipotecario"
                    class="btn-primary"
                    containerClass="mb-3"
                    onClick={handleShowLOModalMortgage}
                    showIcon={false}
                  />
                  <Button
                    id="apply-for-home-equity-link"
                    type="button"
                    text="Solicitar un préstamo sobre el valor líquido de la vivienda"
                    class="btn-anchor-link text-left"
                    onClick={handleShowLOModalHomeEquity}
                    icon={{ lib: "fal", name: "arrow-right", class: "mt-1 ml-2" }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default QuickLinksFlex;

