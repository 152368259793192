import { useLocation } from "@reach/router";
import { useEffect } from "react";

/**
 * This hook enables the page param goto, which accepts the string ID of an element in the page.
 * If the element is present on load, it will scroll to that point in the page.
 */
const useGoTo = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const targetId = searchParams.get("goto");

  useEffect(() => {
    if (!targetId) return;

    const element = document.getElementById(targetId);

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [targetId]);
};

export default useGoTo;
